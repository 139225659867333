var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row", staticStyle: { "padding-top": "1em" } },
    [
      _c("div", { staticClass: "col-md-3 text-center" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.model.name))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group-vertical",
            attrs: { role: "group", "aria-label": "..." }
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-default",
                attrs: { href: "/history/nas/" + this.state.id }
              },
              [_vm._v("История изменений")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-default text-danger",
                on: { click: _vm.delNas }
              },
              [
                _c("span", { staticClass: "glyphicon glyphicon-trash" }),
                _vm._v(" Удалить\n          ")
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-9" }, [
        _c("form", { staticClass: "form-horizontal" }, [
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Select", {
                attrs: {
                  size: 7,
                  label: "Статус",
                  name: "disable",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  options: _vm.disableOptions
                },
                model: {
                  value: _vm.model.disable,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "disable", $$v)
                  },
                  expression: "model.disable"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Название",
                  size: 7,
                  name: "name",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Тип",
                  size: 7,
                  options: _vm.$store.state.preload["/nas-types"],
                  name: "type",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.type,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "type", $$v)
                  },
                  expression: "model.type"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Описание",
                  size: 7,
                  name: "description",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.description,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "description", $$v)
                  },
                  expression: "model.description"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Input", {
                attrs: {
                  label: "IP адрес",
                  size: 7,
                  name: "ip",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.ip,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "ip", $$v)
                  },
                  expression: "model.ip"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Собственный пул",
                  size: 7,
                  name: "own_pool",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info:
                    "Название пула, определенного на сервере доступа, из которого отдаются адреса абонентам"
                },
                model: {
                  value: _vm.model.own_pool,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "own_pool", $$v)
                  },
                  expression: "model.own_pool"
                }
              }),
              _vm._v(" "),
              _c("Select2", {
                attrs: {
                  label: "Пулы биллинга",
                  size: 7,
                  name: "ippool",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  multiple: true,
                  options: _vm.ipPoolOptions,
                  save: _vm.saveIppool,
                  info:
                    "Пулы для сервера доступа, адреса в которых распределяет биллинг"
                },
                model: {
                  value: _vm.model.ippool,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "ippool", $$v)
                  },
                  expression: "model.ippool"
                }
              }),
              _vm._v(" "),
              _c("Select", {
                attrs: {
                  label: "Есть пулы ошибок",
                  size: 7,
                  name: "has_error_pools",
                  options: _vm.yesNoOptions,
                  info:
                    "Пулы ошибок error-* определены на сервере доступа, а не в биллинге",
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.has_error_pools,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "has_error_pools", $$v)
                  },
                  expression: "model.has_error_pools"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Input", {
                attrs: {
                  label: "Время alive",
                  size: 7,
                  name: "alive",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info:
                    "Интервал обновления данных о сессии. При 6 просроченных интервалах сессия считается завершившейся."
                },
                model: {
                  value: _vm.model.alive,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "alive", $$v)
                  },
                  expression: "model.alive"
                }
              }),
              _vm._v(" "),
              _c("TextArea", {
                attrs: {
                  label: "Параметры скорости",
                  size: 7,
                  name: "speed_pairs",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info:
                    "Формат строк:\n            Attribute := Value Установить атрибут\n            Attribute = Value Установить атрибут, если у него не было значения\n            Attribute += Value Добавить атрибут к списку\n            Можно использовать подстановки {SPEED} {SPEED8} {SPEED4} {SPEED_KB}"
                },
                model: {
                  value: _vm.model.speed_pairs,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "speed_pairs", $$v)
                  },
                  expression: "model.speed_pairs"
                }
              }),
              _vm._v(" "),
              _c("TextArea", {
                attrs: {
                  label: "Параметры RADIUS",
                  size: 7,
                  name: "rad_pairs",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info:
                    "Формат строк:\n            Attribute := Value Установить атрибут\n            Attribute = Value Установить атрибут, если у него не было значения\n            Attribute += Value Добавить атрибут к списку"
                },
                model: {
                  value: _vm.model.rad_pairs,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "rad_pairs", $$v)
                  },
                  expression: "model.rad_pairs"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "URL съема трафика",
                  size: 7,
                  name: "traffic_url",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info:
                    "Адрес вебсокета, отдающего данные по трафику с данного сервера доступа"
                },
                model: {
                  value: _vm.model.traffic_url,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "traffic_url", $$v)
                  },
                  expression: "model.traffic_url"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c(
                "Collapse",
                { attrs: { title: "Контекстные параметры" } },
                [_c("ContextPairs", { attrs: { id: this.state.id } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "well well-sm" },
            [
              _c("Input", {
                attrs: {
                  label: "Порт PoD",
                  size: 7,
                  name: "pod_port",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info: "Порт для отсылки пакетов отключения сессий"
                },
                model: {
                  value: _vm.model.pod_port,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "pod_port", $$v)
                  },
                  expression: "model.pod_port"
                }
              }),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  label: "Пароль PoD",
                  size: 7,
                  name: "pod_password",
                  apiTopic: "client",
                  apiPath: _vm.apiPath,
                  info: "Пароль для пакетов отключения сессий"
                },
                model: {
                  value: _vm.model.pod_password,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "pod_password", $$v)
                  },
                  expression: "model.pod_password"
                }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", {
        staticClass: "glyphicon glyphicon-th-list",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("  "),
      _c("a", { attrs: { href: "/nas" } }, [_vm._v("Серверы доступа")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }