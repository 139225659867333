var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-9" }, [
      _c("form", { staticClass: "form-horizontal" }, [
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Select", {
              attrs: {
                label: "Статус",
                size: 7,
                error: _vm.err.disable,
                options: _vm.disableOptions
              },
              on: {
                input: function($event) {
                  _vm.err.disable = null
                }
              },
              model: {
                value: _vm.model.disable,
                callback: function($$v) {
                  _vm.$set(_vm.model, "disable", $$v)
                },
                expression: "model.disable"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Тип",
                size: 7,
                error: _vm.err.type,
                options: _vm.$store.state.preload["/nas-types"]
              },
              on: {
                input: function($event) {
                  _vm.err.type = null
                }
              },
              model: {
                value: _vm.model.type,
                callback: function($$v) {
                  _vm.$set(_vm.model, "type", $$v)
                },
                expression: "model.type"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: { label: "Название", size: 7, error: _vm.err.name },
              on: {
                input: function($event) {
                  _vm.err.name = null
                }
              },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: { label: "Описание", size: 7, error: _vm.err.description },
              on: {
                input: function($event) {
                  _vm.err.description = null
                }
              },
              model: {
                value: _vm.model.description,
                callback: function($$v) {
                  _vm.$set(_vm.model, "description", $$v)
                },
                expression: "model.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Input", {
              attrs: { label: "IP", size: 7, error: _vm.err.ip },
              on: {
                input: function($event) {
                  _vm.err.ip = null
                }
              },
              model: {
                value: _vm.model.ip,
                callback: function($$v) {
                  _vm.$set(_vm.model, "ip", $$v)
                },
                expression: "model.ip"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Собственный пул",
                size: 7,
                error: _vm.err.own_pool,
                info:
                  "Название пула, определенного на сервере доступа, из которого отдаются адреса абонентам"
              },
              on: {
                input: function($event) {
                  _vm.err.own_pool = null
                }
              },
              model: {
                value: _vm.model.own_pool,
                callback: function($$v) {
                  _vm.$set(_vm.model, "own_pool", $$v)
                },
                expression: "model.own_pool"
              }
            }),
            _vm._v(" "),
            _c("Select2", {
              attrs: {
                label: "Пулы биллинга",
                size: 7,
                error: _vm.err.ippool,
                multiple: true,
                options: _vm.ipPoolOptions,
                info:
                  "Пулы для сервера доступа, адреса в которых распределяет биллинг"
              },
              on: {
                input: function($event) {
                  _vm.err.ippool = null
                }
              },
              model: {
                value: _vm.model.ippool,
                callback: function($$v) {
                  _vm.$set(_vm.model, "ippool", $$v)
                },
                expression: "model.ippool"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              attrs: {
                label: "Есть пулы ошибок",
                size: 7,
                error: _vm.err.has_error_pools,
                options: _vm.yesNoOptions,
                info:
                  "Пулы ошибок error-* определены на сервере доступа, а не в биллинге"
              },
              on: {
                input: function($event) {
                  _vm.err.has_error_pools = null
                }
              },
              model: {
                value: _vm.model.has_error_pools,
                callback: function($$v) {
                  _vm.$set(_vm.model, "has_error_pools", $$v)
                },
                expression: "model.has_error_pools"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Input", {
              attrs: {
                label: "Время alive",
                size: 7,
                error: _vm.err.alive,
                info:
                  "Интервал обновления данных о сессии. При 6 просроченных интервалах сессия считается завершившейся."
              },
              on: {
                input: function($event) {
                  _vm.err.alive = null
                }
              },
              model: {
                value: _vm.model.alive,
                callback: function($$v) {
                  _vm.$set(_vm.model, "alive", $$v)
                },
                expression: "model.alive"
              }
            }),
            _vm._v(" "),
            _c("TextArea", {
              attrs: {
                label: "Параметры скорости",
                size: 7,
                error: _vm.err.speed_pairs,
                info:
                  "Формат строк:\n            Attribute := Value Установить атрибут\n            Attribute = Value Установить атрибут, если у него не было значения\n            Attribute += Value Добавить атрибут к списку\n            Можно использовать подстановки {SPEED} {SPEED8} {SPEED4} {SPEED_KB}"
              },
              on: {
                input: function($event) {
                  _vm.err.speed_pairs = null
                }
              },
              model: {
                value: _vm.model.speed_pairs,
                callback: function($$v) {
                  _vm.$set(_vm.model, "speed_pairs", $$v)
                },
                expression: "model.speed_pairs"
              }
            }),
            _vm._v(" "),
            _c("TextArea", {
              attrs: {
                label: "Параметры RADIUS",
                size: 7,
                error: _vm.err.rad_pairs,
                info:
                  "Формат строк:\n            Attribute := Value Установить атрибут\n            Attribute = Value Установить атрибут, если у него не было значения\n            Attribute += Value Добавить атрибут к списку"
              },
              on: {
                input: function($event) {
                  _vm.err.rad_pairs = null
                }
              },
              model: {
                value: _vm.model.rad_pairs,
                callback: function($$v) {
                  _vm.$set(_vm.model, "rad_pairs", $$v)
                },
                expression: "model.rad_pairs"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "URL съема трафика",
                size: 7,
                error: _vm.err.traffic_url,
                info:
                  "Адрес вебсокета, отдающего данные по трафику с данного сервера доступа"
              },
              on: {
                input: function($event) {
                  _vm.err.traffic_url = null
                }
              },
              model: {
                value: _vm.model.traffic_url,
                callback: function($$v) {
                  _vm.$set(_vm.model, "traffic_url", $$v)
                },
                expression: "model.traffic_url"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c(
              "Collapse",
              { attrs: { title: "Контекстные параметры" } },
              [
                _c("ContextPairsNew", {
                  model: {
                    value: _vm.context,
                    callback: function($$v) {
                      _vm.context = $$v
                    },
                    expression: "context"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "well well-sm" },
          [
            _c("Input", {
              attrs: {
                label: "Порт PoD",
                size: 7,
                error: _vm.err.pod_port,
                info: "Порт для отсылки пакетов отключения сессий"
              },
              on: {
                input: function($event) {
                  _vm.err.pod_port = null
                }
              },
              model: {
                value: _vm.model.pod_port,
                callback: function($$v) {
                  _vm.$set(_vm.model, "pod_port", $$v)
                },
                expression: "model.pod_port"
              }
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Пароль PoD",
                size: 7,
                error: _vm.err.pod_password,
                info: "Пароль для пакетов отключения сессий"
              },
              on: {
                input: function($event) {
                  _vm.err.pod_password = null
                }
              },
              model: {
                value: _vm.model.pod_password,
                callback: function($$v) {
                  _vm.$set(_vm.model, "pod_password", $$v)
                },
                expression: "model.pod_password"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn btn-primary", on: { click: _vm.submit } },
          [_c("Icon", { attrs: { name: "save" } }), _vm._v(" Сохранить")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-3 text-center" }, [
      _c("span", { staticClass: "lead" }, [_vm._v("Новый сервер доступа")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }