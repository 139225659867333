var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container context-param" },
    [
      _vm._l(_vm.model, function(rec, i) {
        return _c("div", { staticClass: "row well" }, [
          _c("table", { attrs: { width: "100%" } }, [
            _c("tr", [
              _c(
                "td",
                { attrs: { width: "40%" } },
                [
                  _c("GenInput", {
                    attrs: {
                      size: 9,
                      "label-size": 2,
                      entity: "nas-context",
                      attr: "context",
                      apiDomain: _vm.domain,
                      apiTopic: "client",
                      apiPath: _vm.model[i].entity
                    },
                    model: {
                      value: _vm.model[i].context,
                      callback: function($$v) {
                        _vm.$set(_vm.model[i], "context", $$v)
                      },
                      expression: "model[i].context"
                    }
                  }),
                  _vm._v(" "),
                  _c("GenInput", {
                    attrs: {
                      size: 9,
                      "label-size": 2,
                      entity: "nas-context",
                      attr: "cond",
                      apiDomain: _vm.domain,
                      apiTopic: "client",
                      apiPath: _vm.model[i].entity
                    },
                    model: {
                      value: _vm.model[i].cond,
                      callback: function($$v) {
                        _vm.$set(_vm.model[i], "cond", $$v)
                      },
                      expression: "model[i].cond"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { attrs: { width: "2%" } }),
              _vm._v(" "),
              _c(
                "td",
                { attrs: { width: "47%" } },
                [
                  _c("GenInput", {
                    attrs: {
                      rows: 4,
                      size: 10,
                      "label-size": 2,
                      entity: "nas-context",
                      attr: "rad_pairs",
                      apiDomain: _vm.domain,
                      apiTopic: "client",
                      apiPath: _vm.model[i].entity
                    },
                    model: {
                      value: _vm.model[i].rad_pairs,
                      callback: function($$v) {
                        _vm.$set(_vm.model[i], "rad_pairs", $$v)
                      },
                      expression: "model[i].rad_pairs"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { attrs: { width: "3%", align: "right" } },
                [
                  _c("Icon", {
                    attrs: { name: "trash" },
                    on: {
                      click: function($event) {
                        return _vm.deleteContext(i)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-11" }, [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-1" },
          [
            _c("Icon", {
              attrs: { name: "plus" },
              on: { click: _vm.addContext }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }