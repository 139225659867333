var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready
        ? _c("SearchTable", {
            ref: "table",
            attrs: {
              name: "nas",
              title: "Серверы доступа",
              columns: _vm.columns,
              dynamic: "",
              apiTopic: "client",
              apiPath: "/nas?paged=1",
              metaApiTopic: "client",
              metaApiPath: "/meta/nas/search",
              rowClass: _vm.rowClass,
              toolbar: _vm.toolbar,
              state: _vm.state.params
            },
            on: { "toolbar-click": _vm.toolbarClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "name",
                  fn: function({ rec }) {
                    return [
                      _c("a", { attrs: { href: "/nas/" + rec.id } }, [
                        _vm._v(_vm._s(rec.name))
                      ])
                    ]
                  }
                },
                {
                  key: "disable",
                  fn: function({ rec }) {
                    return [
                      rec.disable
                        ? _c("span", {
                            staticClass: "glyphicon glyphicon-minus",
                            attrs: { "aria-hidden": "true" }
                          })
                        : _c("span", [_vm._v("✓")])
                    ]
                  }
                }
              ],
              null,
              false,
              4027564669
            )
          })
        : _c("SkelSearchTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }