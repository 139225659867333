<template>
  <div class="row" style="padding-top: 1em">
      <div class="col-md-3 text-center">
        <ol class="breadcrumb">
          <li><span class="glyphicon glyphicon-th-list" aria-hidden="true"></span>&nbsp;&nbsp;<a href="/nas">Серверы доступа</a></li>
          <li class="active">{{ model.name }}</li>
        </ol>

        <div class="btn-group-vertical" role="group" aria-label="...">
            <a class="btn btn-default" :href="'/history/nas/'+this.state.id">История изменений</a>
            <a class="btn btn-default text-danger" @click=delNas >
                <span class="glyphicon glyphicon-trash"></span> Удалить
            </a>
        </div>
      </div>

      <div class="col-md-9">
        <form class="form-horizontal">

          <div class="well well-sm">
            <Select :size=7 label="Статус" v-model="model.disable" name=disable apiTopic=client :apiPath=apiPath :options=disableOptions />
            <Input label="Название" :size=7 v-model="model.name" name=name apiTopic=client :apiPath=apiPath />
            <Select label="Тип" :size=7 v-model="model.type" :options="$store.state.preload['/nas-types']" name=type apiTopic=client :apiPath=apiPath />
            <Input label="Описание" :size=7 v-model="model.description" name=description apiTopic=client :apiPath=apiPath />
          </div>

          <div class="well well-sm">
            <Input label="IP адрес" :size=7 v-model="model.ip" name=ip apiTopic=client :apiPath=apiPath />
            <Input label="Собственный пул" :size=7 v-model="model.own_pool" name=own_pool apiTopic=client :apiPath=apiPath info="Название пула, определенного на сервере доступа, из которого отдаются адреса абонентам" />

            <Select2 label="Пулы биллинга" :size=7 v-model="model.ippool" name=ippool apiTopic=client :apiPath=apiPath 
             :multiple=true :options=ipPoolOptions :save=saveIppool
             info="Пулы для сервера доступа, адреса в которых распределяет биллинг" />

            <Select label="Есть пулы ошибок" :size=7 v-model="model.has_error_pools" name=has_error_pools 
            :options=yesNoOptions
            info="Пулы ошибок error-* определены на сервере доступа, а не в биллинге" apiTopic=client :apiPath=apiPath />
          </div>
       
          <div class="well well-sm">
            <Input label="Время alive" :size=7 v-model="model.alive" name=alive apiTopic=client :apiPath=apiPath info="Интервал обновления данных о сессии. При 6 просроченных интервалах сессия считается завершившейся."/>

            <TextArea label="Параметры скорости" :size=7 v-model="model.speed_pairs" name=speed_pairs apiTopic=client :apiPath=apiPath info="Формат строк:
              Attribute := Value Установить атрибут
              Attribute = Value Установить атрибут, если у него не было значения
              Attribute += Value Добавить атрибут к списку
              Можно использовать подстановки {SPEED} {SPEED8} {SPEED4} {SPEED_KB}" />

            <TextArea label="Параметры RADIUS" :size=7 v-model="model.rad_pairs" name=rad_pairs apiTopic=client :apiPath=apiPath info="Формат строк:
              Attribute := Value Установить атрибут
              Attribute = Value Установить атрибут, если у него не было значения
              Attribute += Value Добавить атрибут к списку" />

            <Input label="URL съема трафика" :size=7 v-model="model.traffic_url" name=traffic_url apiTopic=client :apiPath=apiPath info="Адрес вебсокета, отдающего данные по трафику с данного сервера доступа"/>
          </div>
          
          <div class="well well-sm">
            <Collapse title="Контекстные параметры">
              <ContextPairs :id=this.state.id />
            </Collapse>
          </div>

          <div class="well well-sm">
            <Input label="Порт PoD" :size=7 v-model="model.pod_port" name=pod_port apiTopic=client :apiPath=apiPath info="Порт для отсылки пакетов отключения сессий"/>

            <Input label="Пароль PoD" :size=7 v-model="model.pod_password" name=pod_password apiTopic=client :apiPath=apiPath info="Пароль для пакетов отключения сессий"/>
          </div>

        </form>
      </div>
  </div>
</template>

<script>

import _ from "underscore"

import Select from "common/visual/cooper/Select.vue"
import Select2 from "common/visual/cooper/Select2.vue"
import Input from "common/visual/cooper/Input.vue"
import Number from "common/visual/cooper/Number.vue"
import TextArea from "common/visual/cooper/TextArea.vue"
import SkelInput from "common/visual/SkelInput.vue"
import ContextPairs from "nas/page/ContextPairs.vue"
import Collapse from "common/visual/Collapse.vue"

import { notifyHttpError } from "common"
import darsan from "darsan"
import { goto } from "navigation"

export default {
  name: "NasEditor",
  
  components: {Select, Input, SkelInput, Number, TextArea, Select2, ContextPairs, Collapse},

  props: {
    state: Object,
  },
  
  created() {

  },
  
  data () {
    return {
      ready: false,
      model: {},
      err:   {},
      lists: {},
    }
  },
  
  activated () {
    Promise.all([
      this.ensure(["nas-types", "nas-ippools"]),
      darsan.get("", "client", this.apiPath),
      darsan.get("", "client", this.apiPath+"/ippool")
    ])
    .then(([a, rec, pools]) => {
      this.ready = true 

      for (const name of Object.keys(rec)) {
        if (name == "has_error_pools" || name == "disable") {
          this.$set(this.model, name, rec[name] ? "true" : "false")
        } else {
          this.$set(this.model, name, rec[name])
        }
      }
      
      this.$set(this.model, "ippool", pools.map(el => el.pool_id))
    })
    .catch(xhr => notifyHttpError(xhr))
  },
  
  methods: {
    delNas () {
      if (confirm("Вы уверены, что хотите удалить сервер доступа '" + this.model.name + "'?" )) {
        darsan.delete("", "client", "/nas/" + this.state.id)
        .done(() => goto("/nas"))
      }
    },
    
    saveIppool(val, old)
    {
      const added = _.difference(val, old)
      const removed = _.difference(old, val)

      if (added.length)
      {
        return darsan.put("", "client", this.apiPath+"/ippool", {pool_id: added[0]})
      }
      else if (removed.length)
      {
        return darsan.delete("", "client", this.apiPath+"/ippool", {pool_id: removed[0]})
      }
    },
  },
  
  computed: {
    apiPath () {
      return "/nas/" + this.state.id
    },

    disableOptions () {
      return [
        { value: "true",  text: "Отключен" },
        { value: "false", text: "Активен"  }
      ]
    },

    yesNoOptions() {
      return [
        { value: "false", text: "Нет" },
        { value: "true",  text: "Да"  },
      ]
    },
    
    ipPoolOptions()
    {
      return this.$store.state.preload['/nas-ippools'].map(el => { return {text: el.name, value: el.id} })
    },

  },
  
}
</script>
