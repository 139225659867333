<template>
  <div class="container context-param">
	  <div class="row well" v-for="(rec,i) in model">
	    <table width="100%">
	      <tr>
	        <td width="40%">
	          <GenInput :size=9 :label-size=2 entity="nas-context" attr=context v-model="model[i].context" :apiDomain=domain apiTopic=client :apiPath="model[i].entity" />
	          <GenInput :size=9 :label-size=2 entity="nas-context" attr=cond v-model="model[i].cond" :apiDomain=domain apiTopic=client :apiPath="model[i].entity" />
          </td>
          <td width="2%"></td>
          <td width="47%">
	          <GenInput :rows=4 :size=10 :label-size=2 entity="nas-context" attr=rad_pairs v-model="model[i].rad_pairs" :apiDomain=domain apiTopic=client :apiPath="model[i].entity" />
          </td>
          <td width="3%" align="right">
            <Icon name=trash @click=deleteContext(i) />
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
      <div class="col-11">&nbsp;</div>
      <div class="col-1"><Icon name=plus @click=addContext /></div>
    </div>
  </div>
</template>

<script>
import darsan from "darsan"
import GenInput from "common/visual/cooper/GenInput.vue"

export default {
  name: "ContextPairs",
  
  components: {GenInput},
  
  props: {
    id: [Number,String]
  },
  
  data()
  {
    return {
      apiDomain: "",
      domain: config.domain,
      model: [],
    }
  },
  
  methods: {
    async loadData()
    {
      this.model = await darsan.get("", "client", `/nas/${this.id}/context`)
    },
    
    async deleteContext(i)
    {
      await darsan.delete("", "client", this.model[i].entity)
      this.model.splice(i, 1)
    },
    
    async addContext()
    {
      await darsan.post("", "client", `/nas/${this.id}/context`)
      this.loadData()
    },
  },
  
  watch: {
    id: {
      immediate: true,
      handler: function()
      {
        this.loadData()
      }
    },
  },
  
}
</script>
